import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "./logo.png";

const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6">DanceMaps</Typography>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/dance_dictionary"
            sx={{ textAlign: "center" }}
          >
            <ListItemText primary="Dance Dictionary" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/festival"
            sx={{ textAlign: "center" }}
          >
            <ListItemText primary="Festival Calendar" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/local_party"
            sx={{ textAlign: "center" }}
          >
            <ListItemText primary="Local Party" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/songs_charts"
            sx={{ textAlign: "center" }}
          >
            <ListItemText primary="Songs Charts" />
          </ListItemButton>
        </ListItem>

        {/* <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/about"
            sx={{ textAlign: "center" }}
          >
            <ListItemText primary="About" />
          </ListItemButton>
        </ListItem> */}
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/contact"
            sx={{ textAlign: "center" }}
          >
            <ListItemText primary="Contact" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <AppBar
        component="nav"
        position="sticky"
        sx={{
          backgroundColor: "#333",
          height: "8vh",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Toolbar>
          <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            <img
              src={Logo}
              alt="DanceMaps Logo"
              width="30"
              height="30"
              style={{ marginRight: "10px", marginLeft: "10px" }}
            />
          </Link>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
              transition: "color 0.3s",
            }}
          >
            <Link to="/" style={{ textDecoration: "none", color: "white" }}>
              DanceMaps
            </Link>
          </Typography>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Button component={Link} to="/dance_dictionary" color="inherit">
              Dance Dictionary
            </Button>
            <Button component={Link} to="/festival" color="inherit">
              Festival Calendar
            </Button>
            <Button component={Link} to="/local_party" color="inherit">
              Local Party
            </Button>
            <Button component={Link} to="/songs_charts" color="inherit">
              Songs Charts
            </Button>
            {/* <Button component={Link} to="/about" color="inherit">
              About
            </Button> */}
            <Button component={Link} to="/contact" color="inherit">
              Contact
            </Button>
          </Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "240px",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};

export default Header;
