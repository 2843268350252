import { Box, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";

import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MapIcon from "@mui/icons-material/Map";
import ViewListIcon from "@mui/icons-material/ViewList";
import Grid from "@mui/material/Grid";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useScreenSize } from "../../context/screenSizeContext";
import { useFavoritesStore } from "../../store/favoritesStore";
import { useStore } from "../../store/store.js";
import FestivalCard from "./FestivalCard.js";
import FestivalMap from "./FestivalMap";
import "./Festivals.css";
import FestivalFilters from "./festivals-filter/FestivalsFilter.js";

function Festivals() {
  const [festivals, setFestivals] = useState([]);
  const [filters, setFilters] = useState({
    location: "",
    startDate: new Date(),
    type: "",
  });

  const [filterTypes, setFilterTypes] = useState([]);
  const danceStore = useStore();
  const [view, setView] = useState("list");
  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const isSmallScreen = useScreenSize();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/festivals/`)
      .then((response) => response.json())
      .then((data) => {
        setFestivals(data);
        // Create a unique list of all types
        const types = [
          ...new Set(
            data.flatMap((festival) =>
              festival.type.split(", ").map((type) => type.trim())
            )
          ),
        ];
        setFilterTypes(types);
        // Initialize filters.type with the unique types, all set to false
        setFilters((prevFilters) => ({
          ...prevFilters,
          type: types.reduce((acc, type) => ({ ...acc, [type]: false }), {}),
        }));
      })
      .catch((error) => console.error("Error fetching data: ", error));
    danceStore.reset();
  }, []);

  const favorites = useFavoritesStore((state) => state.favorites);
  const [selectedTypes, setSelectedTypes] = useState([]);

  const filteredFestivals = festivals.filter((festival) => {
    const locationMatch =
      !filters.location ||
      festival.location
        .toLowerCase()
        .includes(filters.location.toLowerCase()) ||
      festival.name.toLowerCase().includes(filters.location.toLowerCase());

    const typeMatch =
      selectedTypes.length === 0 ||
      selectedTypes.every((type) => festival.type.includes(type));

    const isFavorite = favorites["festivals"].includes(festival.id);
    if (filters.showFavoritesOnly) {
      return isFavorite;
    }

    if (filters.startDate) {
      const filterDate = new Date(filters.startDate).setHours(0, 0, 0, 0); // Start of day in user's timezone
      const festivalDate = new Date(festival.start).setHours(0, 0, 0, 0); // Start of day in festival's timezone

      return festivalDate >= filterDate && locationMatch && typeMatch;
    }

    return locationMatch && typeMatch;
  });

  const onFilterChange = (e) => {
    const { name, value, checked } = e.target;

    setFilters((prevFilters) => {
      let updatedFilters = { ...prevFilters };

      if (name === "type") {
        updatedFilters.type = {
          ...prevFilters.type,
          [value]: checked,
        };
      } else {
        updatedFilters[name] = value;
      }

      return updatedFilters;
    });
  };

  const [showFavoritesOnly, setShowFavoritesOnly] = useState(false);

  const handleFavoriteToggle = () => {
    setShowFavoritesOnly(!showFavoritesOnly);
    // Notify the parent component about the change
    onFilterChange({
      target: { name: "showFavoritesOnly", value: !showFavoritesOnly },
    });
  };

  return (
    <>
      {/* Filters and View Toggle */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              height: "5vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              pt: 2,
            }}
          >
            {/* Show Favorites Only */}
            {showFavoritesOnly ? (
              <FavoriteIcon
                onClick={handleFavoriteToggle}
                style={{ cursor: "pointer" }}
                sx={{
                  color: "red",
                }}
              />
            ) : (
              <FavoriteBorderIcon
                onClick={handleFavoriteToggle}
                style={{ cursor: "pointer" }}
                sx={{
                  color: "black",
                }}
              />
            )}
            {/* Date Filter */}
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
              <MobileDatePicker
                label="Filter by date"
                value={dayjs(filters.startDate)}
                inputFormat="dd.mm.yyyy"
                closeOnSelect={true}
                onChange={(date) =>
                  onFilterChange({ target: { name: "startDate", value: date } })
                }
                slotProps={{
                  textField: {
                    size: isSmallScreen ? "small" : "small",
                    sx: {
                      "& .MuiInputBase-input": {
                        fontSize: isSmallScreen ? "0.8rem" : "1rem",
                        width: isSmallScreen ? "80px" : "100px",
                      },
                    },
                  },
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            {/* Filters */}
            <Box>
              <FestivalFilters
                selectedTypes={selectedTypes}
                setSelectedTypes={setSelectedTypes}
                filterTypes={filterTypes}
              />
            </Box>
            {/* View Toggle */}
            <ToggleButtonGroup
              value={view}
              exclusive
              onChange={handleViewChange}
              aria-label="View mode"
            >
              <ToggleButton value="map" aria-label="map view">
                <MapIcon />
              </ToggleButton>
              <ToggleButton value="list" aria-label="list view">
                <ViewListIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ mx: 1, mb: 2 }}>
          <TextField
            label="Search location or name"
            variant="outlined"
            name="location"
            value={filters.location}
            onChange={onFilterChange}
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
      {/* Content (List or Map) */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {view === "list" ? (
            // List View
            <Grid
              container
              spacing={2}
              sx={{ height: "78vh", overflowY: "auto" }}
            >
              {filteredFestivals.map((festival) => (
                <Grid item xs={12} sm={6} md={4} xl={3} key={festival.id}>
                  <FestivalCard festival={festival} />
                </Grid>
              ))}
            </Grid>
          ) : (
            // Map View
            <FestivalMap filteredFestivals={filteredFestivals} />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Festivals;
