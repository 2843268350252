import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MapIcon from "@mui/icons-material/Map";
import ViewListIcon from "@mui/icons-material/ViewList";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet/dist/leaflet.css";
import { useQueryState } from "nuqs";
import React, { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { useFavoritesStore } from "../../store/favoritesStore.js";
import LocalPartyList from "./LocalPartyList.js";
import LocalPartyMap from "./LocalPartyMap.js";
import PartyFilter from "./PartyFilter.js";

const LocalPartyOrg = () => {
  const { initParties } = useLoaderData(); // Access loader data
  const [parties, setParties] = useState(initParties);

  const [showFavoritesOnly, setShowFavoritesOnly] = useState(false);

  const [partyTypeFilterRaw, setPartyTypeFilter] = useQueryState("type");
  const [partyTypeFilter, setPartyTypeFilterArray] = useState([]);

  // Ensure partyTypeFilter is always an array
  useEffect(() => {
    if (typeof partyTypeFilterRaw === "string") {
      setPartyTypeFilterArray(partyTypeFilterRaw.split(","));
    } else if (Array.isArray(partyTypeFilterRaw)) {
      setPartyTypeFilterArray(partyTypeFilterRaw);
    } else {
      setPartyTypeFilterArray([]);
    }
  }, [partyTypeFilterRaw]);

  const favorites = useFavoritesStore((state) => state.favorites);

  const [view, setView] = useQueryState("view", { defaultValue: "map" });

  const [selectedDays, setSelectedDays] = useState([]);

  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleFavoriteToggle = () => {
    setShowFavoritesOnly(!showFavoritesOnly);
  };

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const handleDayChange = (event) => {
    const { value } = event.target;
    setSelectedDays(typeof value === "string" ? value.split(",") : value);
  };

  const filteredParties = parties.filter((party) => {
    const isFavorite = favorites["localParties"].includes(party.id);
    if (showFavoritesOnly) {
      return isFavorite;
    }
    if (partyTypeFilter.length === 0 && selectedDays.length === 0) {
      return true;
    }

    const typeMatch =
      partyTypeFilter.length === 0 ||
      partyTypeFilter.some((filterType) => party.type.includes(filterType));

    const dayMatch =
      selectedDays.length === 0 ||
      party.days_of_week.some((dayObject) =>
        selectedDays.includes(dayObject.name)
      );

    return typeMatch && dayMatch;
  });

  return (
    <>
      {isSmScreen && (
        <Box
          sx={{
            height: "8vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          {showFavoritesOnly ? (
            <FavoriteIcon
              onClick={handleFavoriteToggle}
              style={{ cursor: "pointer" }}
              sx={{ color: "red", margin: 1 }}
            />
          ) : (
            <FavoriteBorderIcon
              onClick={handleFavoriteToggle}
              style={{ cursor: "pointer" }}
              sx={{ color: "black", margin: 1 }}
            />
          )}

          <PartyFilter
            selectedDays={selectedDays}
            setSelectedDays={setSelectedDays}
            partyTypeFilter={partyTypeFilter}
            setPartyTypeFilter={setPartyTypeFilter}
          />
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleViewChange}
            aria-label="View mode"
            sx={{ margin: 1 }}
          >
            <ToggleButton value="map" aria-label="map view">
              <MapIcon />
            </ToggleButton>
            <ToggleButton value="list" aria-label="list view">
              <ViewListIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}
      {view === "list" ? (
        <LocalPartyList
          soonestParties={filteredParties}
          handleViewChange={handleViewChange}
          view={view}
        />
      ) : (
        <LocalPartyMap
          soonestParties={filteredParties}
          showFavoritesOnly={showFavoritesOnly}
          setShowFavoritesOnly={setShowFavoritesOnly}
          partyTypeFilter={partyTypeFilter}
          setPartyTypeFilter={setPartyTypeFilter}
          selectedDays={selectedDays}
          handleDayChange={handleDayChange}
          handleViewChange={handleViewChange}
          view={view}
          setParties={setParties}
        />
      )}
    </>
  );
};

export default LocalPartyOrg;
