import LocationOnIcon from "@mui/icons-material/LocationOn";
import MapIcon from "@mui/icons-material/Map";
import ViewListIcon from "@mui/icons-material/ViewList";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Link,
  List,
  ListItem,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { useScreenSize } from "../../context/screenSizeContext";

const LocalPartyList = ({ soonestParties, view, handleViewChange }) => {
  const isSmallScreen = useScreenSize();

  return (
    <Box>
      <List>
        {soonestParties.map((party) => (
          <ListItem key={party.id}>
            <Card sx={{ width: "100%" }}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                    {party.organizer.charAt(0)}{" "}
                    {/* Use organizer instead of name */}
                  </Avatar>
                }
                title={party.organizer}
                subheader={
                  <Typography variant="body2" color="text.secondary">
                    {party.type}
                  </Typography>
                }
              />
              <CardContent>
                <Link
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    party.address
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="none"
                  color="inherit"
                >
                  <Typography variant="body2" color="text.secondary">
                    <LocationOnIcon sx={{ mr: 0.5 }} />
                    {party.address}
                  </Typography>
                </Link>
                {/* <Typography variant="body2" color="text.secondary">
                <CalendarTodayIcon sx={{ mr: 0.5 }} />
                {party.days_of_week.map((day) => day.name).join(", ")}
              </Typography> */}
              </CardContent>
            </Card>
          </ListItem>
        ))}
        {!isSmallScreen && (
          <Box
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 1000,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              padding: "1rem 1rem 0.5rem 1rem",
              borderRadius: "4px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ToggleButtonGroup
              value={view}
              exclusive
              onChange={handleViewChange}
              aria-label="View mode"
              sx={{ margin: 1 }}
            >
              <ToggleButton value="map" aria-label="map view">
                <MapIcon />
              </ToggleButton>
              <ToggleButton value="list" aria-label="list view">
                <ViewListIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        )}
      </List>
    </Box>
  );
};

export default LocalPartyList;
