import { createLoader } from "nuqs";
import { json, LoaderFunctionArgs } from "react-router";
import { loadSearchParamsMap } from "./url-query-params";

export type LoaderResponse =
  | {
      view: "map" | "list";
      initParties: any[];
      southWestLatitude: number;
      southWestLongitude: number;
      northEastLatitude: number;
      northEastLongitude: number;
    }
  | {
      view: "map" | "list";
      initParties: undefined;
      southWestLatitude: number;
      southWestLongitude: number;
      northEastLatitude: number;
      northEastLongitude: number;
    };

const localPartyLoader = async (
  args: LoaderFunctionArgs
): Promise<LoaderResponse> => {
  const { searchParams } = new URL(args.request.url);

  const {
    view,
    type,
    southWestLatitude,
    southWestLongitude,
    northEastLatitude,
    northEastLongitude,
  } = createLoader(loadSearchParamsMap)(searchParams);

  const urlToFetch = `${process.env.REACT_APP_API_URL}/local-parties/?southWestLatitude=${southWestLatitude}&southWestLongitude=${southWestLongitude}&northEastLatitude=${northEastLatitude}&northEastLongitude=${northEastLongitude}&view=${view}&type=${type}`;

  const response = await fetch(urlToFetch);

  if (!response.ok) {
    throw json({}, { status: response.status });
  }

  const initParties = await response.json();

  return {
    view,
    initParties,
    southWestLatitude,
    southWestLongitude,
    northEastLatitude,
    northEastLongitude,
  };
};

export default localPartyLoader;
