import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { NuqsAdapter } from "nuqs/adapters/react-router/v6";
import React from "react";
import { Helmet } from "react-helmet-async";
import Router from "./router/router";

const App = () => {
  const theme = createTheme({
    palette: {
      primary: { main: "#1976d2" },
      secondary: { main: "#f50057" },
    },
    typography: {
      fontFamily: "Roboto, Arial, sans-serif",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Helmet>
        <title>DanceMaps - Your Ultimate Social Dance Companion</title>
        <meta
          name="description"
          content="Discover a world of social dance figures and exciting festivals. Remember your moves, find events near you, and connect with the global dance community."
        />
        <meta
          name="keywords"
          content="social dancing, social dance festivals, social dance figures, social dance tutorials, dancemaps, dance maps"
        />
        <meta
          property="og:title"
          content="DanceMaps - Your Ultimate Social Dance Companion"
        />
        <meta
          property="og:description"
          content="Discover a world of social dance figures and exciting festivals. Remember your moves, find events near you, and connect with the global dance community."
        />
        <meta property="og:url" content="https://dancemaps.net/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <NuqsAdapter>
        <Router />
      </NuqsAdapter>
    </ThemeProvider>
  );
};

export default App;
