import React from "react";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { useScreenSize } from "../context/screenSizeContext";
import { useStore } from "../store/store.js";

import Contact from "../components/Contact";
import DanceStyle from "../components/DanceStyle";
import Dictionary from "../components/dictionary/Dictionary";
import DictionarySmall from "../components/dictionary/DictionarySmall";
import Disclaimer from "../components/Disclaimer";
import Festivals from "../components/festivals/Festivals";
import HomePage from "../components/HomePage";
import HomePageSmall from "../components/HomePageSmall";
import Impressum from "../components/Impressum";
import localPartyLoader from "../components/localPartyOrg/loader.ts";
import LocalPartyOrg from "../components/localPartyOrg/LocalParty";
import PrivacyPolicy from "../components/PrivacyPolicy";
import Songs from "../components/songs/Songs";
import Soon from "../components/Soon";
import TermsOfService from "../components/TermsOfService";
import BaseLayout from "../layouts/base-layout.js";

const Router = () => {
  const isSmallScreen = useScreenSize();
  const danceStore = useStore();
  const selectedStyle = danceStore.style;

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <BaseLayout>
          <Outlet />
        </BaseLayout>
      ),
      children: [
        {
          path: "/",
          element: isSmallScreen ? <HomePageSmall /> : <HomePage />,
        },
        {
          path: "/dance_dictionary",
          element: <DanceStyle />,
        },
        {
          path: "/contact",
          element: <Contact />,
        },
        {
          path: `/${selectedStyle}_map`,
          element: isSmallScreen ? <DictionarySmall /> : <Dictionary />,
        },
        {
          path: "/festival",
          element: <Festivals />,
        },
        {
          path: "/local_party",
          element: <LocalPartyOrg />,
          loader: localPartyLoader,
        },
        {
          path: "/ticket_trading",
          element: <Soon />,
        },
        {
          path: "/songs_charts",
          element: <Songs />,
        },
        {
          path: "/privacy-policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "/terms-of-service",
          element: <TermsOfService />,
        },
        {
          path: "/disclaimer",
          element: <Disclaimer />,
        },
        {
          path: "/impressum",
          element: <Impressum />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default Router;
