import FilterListIcon from "@mui/icons-material/FilterList";

import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";

function PartyFilter({
  partyTypeFilter,
  setPartyTypeFilter,
  selectedDays,
  setSelectedDays,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dayAnchorEl, setDayAnchorEl] = useState(null); // Add state for day filter anchor
  const open = Boolean(anchorEl);
  const dayOpen = Boolean(dayAnchorEl); // Add state for day filter open
  const partyTypeOptions = ["Bachata", "Kizomba", "Salsa", "Zouk"];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDayClick = (event) => {
    setDayAnchorEl(event.currentTarget);
  };

  const handleDayClose = () => {
    setDayAnchorEl(null);
  };

  const handlePartyTypeFilterChange = (event) => {
    const {
      target: { value, checked },
    } = event;

    setPartyTypeFilter((prevPartyTypeFilter) => {
      if (checked) {
        return [...prevPartyTypeFilter, value];
      } else {
        return prevPartyTypeFilter.filter((type) => type !== value);
      }
    });
  };

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const handleDayMenuChange = (event) => {
    const {
      target: { value },
    } = event;

    setSelectedDays((prevSelectedDays) => {
      if (prevSelectedDays.indexOf(value) > -1) {
        return prevSelectedDays.filter((day) => day !== value);
      } else {
        return [...prevSelectedDays, value];
      }
    });
  };

  return (
    <Box>
      {/* Day Filter */}
      {/* <FormControl size="small" fullWidth>
        <IconButton
          aria-label="filter list"
          aria-controls={dayOpen ? "days-of-week-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={dayOpen ? "true" : undefined}
          onClick={handleDayClick}
        >
          <CalendarTodayIcon />
        </IconButton>
        <Menu
          id="days-of-week-menu"
          anchorEl={dayAnchorEl}
          open={dayOpen}
          onClose={handleDayClose}
          MenuListProps={{
            "aria-labelledby": "filter list",
          }}
        >
          {daysOfWeek.map((day) => (
            <MenuItem key={day}>
              <Checkbox
                value={day}
                checked={selectedDays.indexOf(day) > -1}
                onChange={handleDayMenuChange}
              />
              <ListItemText primary={day} />
            </MenuItem>
          ))}
        </Menu>
      </FormControl> */}

      {/* Type Filter */}
      <FormControl size="small" fullWidth>
        <IconButton
          aria-label="filter list"
          aria-controls={open ? "party-type-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <FilterListIcon />
        </IconButton>
        <Menu
          id="party-type-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "filter list",
          }}
        >
          {partyTypeOptions.map((typeOption) => (
            <MenuItem key={typeOption}>
              <Checkbox
                value={typeOption}
                checked={partyTypeFilter.indexOf(typeOption) > -1}
                onChange={handlePartyTypeFilterChange}
              />
              <ListItemText primary={typeOption} />
            </MenuItem>
          ))}
        </Menu>
      </FormControl>
    </Box>
  );
}

export default PartyFilter;
