import { Box } from "@mui/material";
import React from "react";
import { useNavigation } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loading from "../components/Loading";

const BaseLayout = ({ children }) => {
  const navigation = useNavigation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Header />
      {navigation.state === "loading" ? (
        <Loading /> // Render Loading component when navigation is in "loading" state
      ) : (
        <Box sx={{ minHeight: "84vh" }}>{children}</Box> // Render children otherwise
      )}
      <Footer />
    </Box>
  );
};

export default BaseLayout;
