import {
  Facebook,
  Instagram,
  Language,
  Telegram,
  WhatsApp,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useScreenSize } from "../../context/screenSizeContext.js";
import PartyFavoriteButton from "./PartyFavoriteButton";

const PartyCard = ({ party }) => {
  const { organizer, address, type, days_of_week, description, social_media } =
    party;

  const socialMediaIcons = {
    facebook: <Facebook />,
    whatsapp: <WhatsApp />,
    instagram: <Instagram />,
    telegram: <Telegram />,
    website: <Language />,
  };

  const isSmallScreen = useScreenSize();

  return (
    <Card>
      <Box sx={{ position: "relative", top: 8, right: -5, zIndex: 1 }}>
        <PartyFavoriteButton party={party} />
      </Box>
      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          sx={{
            fontSize: 16,
            marginBottom: 1,
            maxWidth: 200,
          }}
        >
          {organizer}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {address}
        </Typography>
        <Divider sx={{ my: 0.5 }} />
        <Typography
          variant="h3"
          color="text.secondary"
          sx={{ fontSize: 12, marginBottom: 0.25 }}
        >
          {type}
        </Typography>
        {/* <Divider sx={{ my: 0.5 }} />
        <Typography variant="h3" color="text.secondary" sx={{ fontSize: 12 }}>
          {days_of_week.map((day) => day.name).join(", ")}
        </Typography> */}
        {description &&
          !isSmallScreen && ( // Conditionally render description
            <Typography variant="body2" color="text.secondary">
              {description}
            </Typography>
          )}
      </CardContent>
      {social_media && social_media.length > 0 && (
        <CardActions>
          <Grid container spacing={1}>
            {social_media.map((link) => (
              <Grid item key={link.id}>
                <a href={link.url} target="_blank" rel="noopener noreferrer">
                  {socialMediaIcons[link.platform]}
                </a>
              </Grid>
            ))}
          </Grid>
        </CardActions>
      )}
    </Card>
  );
};

export default PartyCard;
