import {
  parseAsArrayOf,
  parseAsFloat,
  parseAsStringLiteral,
  ParserMap,
} from "nuqs";
const filterParserMap = {
  view: parseAsStringLiteral(["map", "list"] as const).withDefault("map"),
  type: parseAsArrayOf(
    parseAsStringLiteral(["Bachata", "Kizomba", "Salsa", "Zouk"] as const)
  ),
  southWestLatitude: parseAsFloat.withDefault(50.8175722),
  southWestLongitude: parseAsFloat.withDefault(6.8541123),
  northEastLatitude: parseAsFloat.withDefault(51.0175722),
  northEastLongitude: parseAsFloat.withDefault(7.0541123),
} satisfies ParserMap;

export const loadSearchParamsMap = { ...filterParserMap };
