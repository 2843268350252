import MapIcon from "@mui/icons-material/Map";
import ViewListIcon from "@mui/icons-material/ViewList";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import "dayjs/locale/de";
import L from "leaflet";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet/dist/leaflet.css";
import { throttle } from "lodash";
import { useQueryState } from "nuqs";
import React from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
  useMapEvents,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { useScreenSize } from "../../context/screenSizeContext.js";
import { usePartyStore } from "../../store/localPartyStore.js";
import markerIconPng from "./marker-icon.png";
import PartyCard from "./PartyCard.js";

const LocalPartyMap = ({
  soonestParties,
  setParties,
  showFavoritesOnly,
  setShowFavoritesOnly,
  partyTypeFilter,
  setPartyTypeFilter,
  selectedDays,
  handleDayChange,
  handleViewChange,
  view,
}) => {
  const [southWestLatitude, setSouthWestLatitude] = useQueryState(
    "southWestLatitude",
    { defaultValue: 50.8175722 }
  );
  const [southWestLongitude, setSouthWestLongitude] = useQueryState(
    "southWestLongitude",
    { defaultValue: 6.8541123 }
  );
  const [northEastLatitude, setNorthEastLatitude] = useQueryState(
    "northEastLatitude",
    { defaultValue: 51.0175722 }
  );
  const [northEastLongitude, setNorthEastLongitude] = useQueryState(
    "northEastLongitude",
    { defaultValue: 7.0541123 }
  );

  const fetchParties = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/local-parties/?southWestLatitude=${southWestLatitude}&southWestLongitude=${southWestLongitude}&northEastLatitude=${northEastLatitude}&northEastLongitude=${northEastLongitude}`
      );

      if (!response.ok) {
        throw new Error(
          `Failed to fetch parties: ${response.status} ${response.statusText}`
        );
      }

      const data = await response.json();
      setParties(data);
    } catch (error) {
      console.error("Error fetching parties:", error);
    }
  };

  // Memoize the throttled function
  const throttledFetchParties = throttle(fetchParties, 2000, {
    leading: true, // Execute immediately on the first call
    trailing: false, // Do not execute again after the throttle period
  });

  const MapEventsComponent = React.useMemo(() => {
    return () => {
      const map = useMap();
      const buffer = 0.05;
      const previousZoom = React.useRef(map.getZoom()); // Store the previous zoom level

      useMapEvents({
        zoomend: () => {
          const currentZoom = map.getZoom();

          // Trigger only on zoom out
          // if (currentZoom < previousZoom.current) {
          const mapBounds = map.getBounds();
          setSouthWestLatitude(mapBounds.getSouthWest().lat - buffer);
          setSouthWestLongitude(mapBounds.getSouthWest().lng - buffer);
          setNorthEastLatitude(mapBounds.getNorthEast().lat + buffer);
          setNorthEastLongitude(mapBounds.getNorthEast().lng + buffer);
          throttledFetchParties();
          // }

          // Update the previous zoom level
          previousZoom.current = currentZoom;
        },

        moveend: () => {
          const mapBounds = map.getBounds();
          setSouthWestLatitude(mapBounds.getSouthWest().lat - buffer);
          setSouthWestLongitude(mapBounds.getSouthWest().lng - buffer);
          setNorthEastLatitude(mapBounds.getNorthEast().lat + buffer);
          setNorthEastLongitude(mapBounds.getNorthEast().lng + buffer);
        }
      });

      return null;
    };
  }, [throttledFetchParties]);

  const markerPosition = usePartyStore((state) => state.markerPosition);

  const isSmallScreen = useScreenSize();

  const customIcon = new L.Icon({
    iconUrl: markerIconPng,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
  });

  const handleFavoriteToggle = () => {
    setShowFavoritesOnly(!showFavoritesOnly);
  };

  const handlePartyTypeFilterChange = (event) => {
    const {
      target: { value },
    } = event;
    setPartyTypeFilter(typeof value === "string" ? value.split(",") : value);
  };

  const partyTypeOptions = ["Bachata", "Kizomba", "Salsa", "Zouk"];
  const DAYS_OF_WEEK = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const calculateCenter = () => {
    if (
      southWestLatitude &&
      southWestLongitude &&
      northEastLatitude &&
      northEastLongitude
    ) {
      const centerLat =
        (parseFloat(southWestLatitude) + parseFloat(northEastLatitude)) / 2;
      const centerLng =
        (parseFloat(southWestLongitude) + parseFloat(northEastLongitude)) / 2;
      return [centerLat, centerLng];
    }
    return [51.505, -0.09];
  };

  const calculateZoom = () => {
    if (
      southWestLatitude &&
      southWestLongitude &&
      northEastLatitude &&
      northEastLongitude
    ) {
      const latDiff = Math.abs(northEastLatitude - southWestLatitude);
      const lngDiff = Math.abs(northEastLongitude - southWestLongitude);

      // Some arbitrary constants - adjust as needed
      const latZoomFactor = 10;
      const lngZoomFactor = 10;

      const latZoom = Math.floor(latZoomFactor - Math.log2(latDiff));
      const lngZoom = Math.floor(lngZoomFactor - Math.log2(lngDiff));

      // Return the smaller of the two zooms to ensure everything is visible
      return Math.min(latZoom, lngZoom);
    }
    return 9;
  };

  const mapCenter = calculateCenter();
  const mapZoom = calculateZoom();

  return (
    <MapContainer
      center={mapCenter}
      zoom={mapZoom}
      style={{ height: isSmallScreen ? "84vh" : "92vh" }}
      zoomControl={false}
    >
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
      />
      <MarkerClusterGroup showCoverageOnHover={false}>
        {soonestParties.map((party) => (
          <Marker
            position={[party.latitude, party.longitude]}
            icon={customIcon}
          >
            <Popup closeOnClick={false} autoClose={true}>
              <PartyCard party={party} />
            </Popup>
          </Marker>
        ))}
      </MarkerClusterGroup>
      {!isSmallScreen && (
        <Box
          sx={{
            position: "absolute",
            top: 10,
            left: 10,
            zIndex: 1000,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            padding: "1rem 1rem 0.5rem 1rem",
            borderRadius: "4px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="party-type-filter-label">Filter</InputLabel>
              <Select
                labelId="party-type-filter-label"
                id="party-type-filter"
                multiple
                value={partyTypeFilter}
                input={<OutlinedInput label="Party Type" />}
                renderValue={(selected) =>
                  selected.length > 0 ? selected.join(", ") : "All"
                }
                onChange={handlePartyTypeFilterChange}
              >
                {partyTypeOptions.map((typeOption) => (
                  <MenuItem key={typeOption} value={typeOption}>
                    <Checkbox
                      checked={partyTypeFilter.indexOf(typeOption) > -1}
                    />
                    <ListItemText primary={typeOption} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="day-filter-label">Day</InputLabel>
              <Select
                labelId="day-filter-label"
                id="day-filter"
                multiple
                value={selectedDays}
                input={<OutlinedInput label="Day" />}
                renderValue={(selected) =>
                  selected.length > 0 ? selected.join(", ") : "All"
                }
                onChange={handleDayChange}
              >
                {DAYS_OF_WEEK.map((day) => (
                  <MenuItem key={day} value={day}>
                    <Checkbox checked={selectedDays.indexOf(day) > -1} />
                    <ListItemText primary={day} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={showFavoritesOnly}
                  onChange={handleFavoriteToggle}
                  name="showFavoritesOnly"
                  color="primary"
                />
              }
              label="Favorites Only"
            />
          </Box>
        </Box>
      )}
      {!isSmallScreen && (
        <Box
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 1000,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            padding: "1rem 1rem 0.5rem 1rem",
            borderRadius: "4px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleViewChange}
            aria-label="View mode"
            sx={{ margin: 1 }}
          >
            <ToggleButton value="map" aria-label="map view">
              <MapIcon />
            </ToggleButton>
            <ToggleButton value="list" aria-label="list view">
              <ViewListIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}
      {markerPosition && (
        <Marker
          position={markerPosition}
          icon={
            new L.icon({
              iconUrl: markerIconPng,
              iconSize: ["auto", 40],
              iconAnchor: [12, 40],
            })
          }
        >
          <Popup>Party Location</Popup>
        </Marker>
      )}
      <MapEventsComponent />
    </MapContainer>
  );
};

export default LocalPartyMap;
