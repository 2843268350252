import Grid from "@mui/material/Grid";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import FestivalMapCard from "./FestivalMapCard.js";
import markerIconPng from "./marker-icon.png";

function FestivalMap({ filteredFestivals }) {
  const customIcon = new L.Icon({
    iconUrl: markerIconPng,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
  });

  return (
    <Grid item xs={12}>
      {/* Map */}
      <MapContainer
        center={[51.505, -0.09]}
        zoom={2}
        style={{ height: "71vh", width: "100%" }}
        zoomControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          noWrap={true}
        />
        <MarkerClusterGroup
          key={filteredFestivals.map((party) => party.id).join(",")}
          showCoverageOnHover={false}
        >
          {filteredFestivals.map(
            (festival) =>
              festival.latitude &&
              festival.longitude && (
                <Marker
                  key={festival.id}
                  position={[festival.latitude, festival.longitude]}
                  icon={customIcon}
                >
                  <Popup>
                    <Grid item xs={12} key={festival.id}>
                      <FestivalMapCard festival={festival} />
                    </Grid>
                  </Popup>
                </Marker>
              )
          )}
        </MarkerClusterGroup>
      </MapContainer>
    </Grid>
  );
}

export default FestivalMap;
